import React, { useState } from 'react';
import { Box, CircularProgress, ListItem, Paper, Theme, useMediaQuery } from '@mui/material';
import { useInfiniteQuery } from 'react-query';
import {
  PersonSearchResultExternalPersonScopeEnum as PersonScopeEnum,
  PersonSearchResultExternal,
} from '@askporter/client-grieg-lyric';
import { Icon, IconSize, AvatarSize, UserAvatar, Autocomplete } from '@askporter/component-library';
import { ManagerRoles, useDebounce, fullName } from '@askporter/utils';
import doPeopleSearch from './utils/doPeopleSearch';

interface PeopleSelectorProps {
  idPrefix: string;
  label: string;
  disabled?: boolean;
  selectedUser: PersonSearchResultExternal;
  onChange: (person: PersonSearchResultExternal) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  textFieldVariant?: 'outlined' | 'filled';
  t: (key: string, options?: Record<string, string | number>) => string;
  textOnlyWhenOutlined?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  readOnlyText?: string;
  fullWidth?: boolean;
}

const PeopleSelector = ({
  idPrefix,
  label,
  disabled,
  selectedUser,
  onChange,
  onBlur,
  textFieldVariant = 'outlined',
  t,
  textOnlyWhenOutlined = true,
  placeholder,
  readOnly = false,
  readOnlyText = undefined,
  fullWidth,
}: PeopleSelectorProps): JSX.Element => {
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [inputValue, setInputValue] = useState('');
  const [request, setRequest] = useState(false);
  const [strokeCount, setStrokeCount] = useState(1);
  const userKeyword = useDebounce<string>(inputValue, 700);

  const { data, isFetchingNextPage, hasNextPage, fetchNextPage, status } = useInfiniteQuery(
    [`people/search`, { search: { freeText: userKeyword } }],
    async ({ pageParam = 1 }) => {
      const response = await doPeopleSearch({
        search: {
          freeText: userKeyword,
          page: pageParam,
          filter: {
            scope: ['USERS', 'TEAMS'],
          },
        },
      });
      return { response, nextPage: pageParam + 1 };
    },
    {
      enabled: request && userKeyword?.length > 2,
      getNextPageParam: (data) => {
        return data?.response?.summary?.totalPages >= data?.nextPage ? data?.nextPage : undefined;
      },
    },
  );

  const getName = (user: PersonSearchResultExternal) => {
    if (user) {
      if (user?.personScope === PersonScopeEnum.User) {
        return fullName(user?.givenName, user?.familyName);
      }
      return user?.name;
    }
    return '';
  };

  const filterManagersTeams = (results: PersonSearchResultExternal[]) => {
    return results.filter(
      (entry) =>
        entry.personScope === PersonScopeEnum.Team ||
        (entry.personScope === PersonScopeEnum.User && ManagerRoles.includes(entry.roleSlug)),
    );
  };

  const opts =
    data?.pages && data?.pages.length > 0
      ? data.pages.reduce((acc, itm) => acc.concat(filterManagersTeams([...itm.response.results])), [])
      : [];

  return (
    <Autocomplete
      isSmallDevice={isSmallDevice}
      idPrefix={idPrefix || 'people-selector'}
      label={label}
      aria-label={label}
      disabled={disabled}
      getOptionLabel={(option: PersonSearchResultExternal) => {
        return getName(option);
      }}
      options={[...opts]}
      value={selectedUser || null}
      placeholder={placeholder}
      isOptionEqualToValue={(opt: PersonSearchResultExternal, val: PersonSearchResultExternal) => {
        return opt?.uid === val?.uid;
      }}
      onKeyPress={() => {
        setRequest(true);
      }}
      onChange={(newValue: PersonSearchResultExternal) => {
        setRequest(false);
        return onChange(newValue);
      }}
      onInputChange={(_: React.SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      noOptionsText={`${t('ns.common:type_to_search')}`}
      loadingText={`${t('ns.common:loading')}`}
      ListboxProps={{
        onScroll: (e: React.SyntheticEvent) => {
          const listboxNode = e.currentTarget;
          if (hasNextPage) {
            if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
              fetchNextPage();
            }
          }
        },
      }}
      startAdornment={
        <>
          {textFieldVariant === 'filled' && <Icon size={IconSize.SM} folder="my-account/05-global-user" />}

          {selectedUser && textFieldVariant === 'outlined' ? (
            <>
              {selectedUser.personScope === PersonScopeEnum.User && (
                <UserAvatar
                  givenName={selectedUser.givenName}
                  familyName={selectedUser.familyName}
                  imagePath={selectedUser?.profilePhoto?.filePath}
                  avatarSize={AvatarSize.SM}
                />
              )}
              {selectedUser.personScope === PersonScopeEnum.Team && (
                <UserAvatar
                  longName={selectedUser.name}
                  imagePath={selectedUser?.profilePhoto?.filePath}
                  avatarSize={AvatarSize.SM}
                />
              )}
            </>
          ) : null}
        </>
      }
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, person: PersonSearchResultExternal) => {
        return (
          <ListItem {...props} key={`${props.id}-${person.uid}`} data-testid={`option-${props.id}`}>
            {person && person?.personScope === PersonScopeEnum.User ? (
              <>
                <Box sx={{ mr: 4 }}>
                  <UserAvatar
                    givenName={person.givenName}
                    familyName={person.familyName}
                    imagePath={person?.profilePhoto?.filePath}
                    avatarSize={AvatarSize.SM}
                  />
                </Box>
                {fullName(person?.givenName, person?.familyName)}
              </>
            ) : (
              <>
                <Box sx={{ mr: 4 }}>
                  <UserAvatar longName={person.name} avatarSize={AvatarSize.SM} />
                </Box>
                {person.name}
              </>
            )}
          </ListItem>
        );
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (strokeCount === 1 && e.key === 'Backspace') {
          setRequest(false);
          setInputValue('');
        }
        setStrokeCount(strokeCount + 1);
      }}
      onFocus={() => {
        setStrokeCount(1);
      }}
      onBlur={(e) => {
        setRequest(false);
        onBlur && onBlur(e);
      }}
      endAdornment={
        <>{(status === 'loading' || isFetchingNextPage) && <CircularProgress color="inherit" size={15} />}</>
      }
      PaperComponent={
        isSmallDevice ? ({ children }) => <Paper style={{ boxShadow: 'none' }}>{children}</Paper> : undefined
      }
      textFieldVariant={textFieldVariant}
      textFieldSx={
        selectedUser && textOnlyWhenOutlined
          ? {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid transparent',
              },

              '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                border: '1px solid transparent',
              },

              '& .MuiInputLabel-outlined, &:hover .MuiInputLabel-outlined.Mui-disabled': {
                opacity: 0,
              },

              '&:hover .MuiInputLabel-outlined, & .MuiInputLabel-outlined.Mui-focused': {
                opacity: 1,
              },
            }
          : {}
      }
      forcePopupIcon={false}
      filterOptions={(x) => x}
      fullWidth={fullWidth}
      {...{ readOnly, readOnlyText }}
    />
  );
};

export default PeopleSelector;
